import "./Software.css";
import tensorflowIcon from "../../images/tensorflowIcon.png";
import rnnIcon from "../../images/rnnIcon.png";
import theano from "../../images/theanoLogo.png";
import pytorch from "../../images/pytorchIcon.svg";
import caffe2 from "../../images/caffe2Icon.svg";
import mylica from "../../images/mylicaIcon.png";
import vowpalwabbit from "../../images/vowpalWabbitIcon.png";
import traindata from "../../images/traindataIcon.png";
import keras from "../../images/kerasIcon.png";
const Software = () => {
    return (
        <div className="software">
            <nav className="navbar navbar-expand-md bg-primary navbar-dark ">
                <div className="container p-3">
                    <a className="navbar-brand p-0 " href="/portal/virtualservers">
                        Phoenix Labs
                    </a>
                </div>
            </nav>
            <div className="d-flex align-items-center">
                <div className="container-fluid">
                    <div className="row mt-4">
                        <div className="col-3 d-flex justify-content-center ">
                            <div className="icon text-light text-center w-50 rounded bg-primary p-2">
                                <div className="rounded bg-light">
                                    <img src={tensorflowIcon} alt="Tensorflow Icon" height={"100px"} />
                                </div>
                                <h5>Tensorflow</h5>
                            </div>
                        </div>
                        <div className="col-3 d-flex justify-content-center">
                            <div className="icon text-light text-center w-50 rounded bg-primary p-2">
                                <div className="rounded bg-light p-1">
                                    <img src={rnnIcon} alt="RNN Icon" height={"100px"} />
                                </div>
                                <h5>RNN</h5>
                            </div>
                        </div>
                        <div className="col-3 d-flex justify-content-center ">
                            <div className="icon text-light text-center w-50 rounded bg-primary p-2">
                                <div className="rounded bg-light pb-1">
                                    <img src={theano} alt="Theano Icon" height={"100px"} width="100px" />
                                </div>
                                <h5>Theano</h5>
                            </div>
                        </div>
                        <div className="col-3 d-flex justify-content-center ">
                            <div className="icon text-light text-center w-50 rounded bg-primary p-2">
                                <div className="rounded bg-light p-1">
                                    <img src={pytorch} alt="Pytorch Icon" height={"100px"} />
                                </div>
                                <h5>Pytorch</h5>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-3 d-flex justify-content-center">
                                <div className="icon text-light text-center w-50 rounded bg-primary p-2">
                                    <div className="rounded bg-light p-2">
                                        <img src={caffe2} alt="Caffe2 Icon" height={"100px"} />
                                    </div>
                                    <h5>Caffe 2</h5>
                                </div>
                            </div>
                            <div className="col-3 d-flex justify-content-center">
                                <div className="icon text-light text-center w-50 rounded bg-primary p-2">
                                    <div className="rounded bg-light">
                                        <img src={mylica} alt="MyLica Icon" height={"100px"} width={"100px"} />
                                    </div>
                                    <h5>My Lica</h5>
                                </div>
                            </div>
                            <div className="col-3 d-flex justify-content-center ">
                                <div className="icon text-light text-center w-50 rounded bg-primary p-2">
                                    <div className="rounded bg-light">
                                        <img src={vowpalwabbit} alt="Vowpal Wabbit Icon" height={"100px"} />
                                    </div>
                                    <h5>Vowpal Wabbit</h5>
                                </div>
                            </div>
                            <div className="col-3 d-flex justify-content-center ">
                                <div className="icon text-light text-center w-50 rounded bg-primary p-2">
                                    <div className="bg-light rounded p-2">
                                        <img src={traindata} alt="Train Data Icon" height={"100px"} />
                                    </div>
                                    <h5>Traindata </h5>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-3 d-flex justify-content-center">
                                <div className="icon text-light text-center w-50 rounded bg-primary p-2">
                                    <div className="rounded " style={{ backgroundColor: "#d00000" }}>
                                        <img src={keras} alt="Keras Icon" height={"100px"} />
                                    </div>
                                    <h5>Keras </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Software;
