import { Link } from "react-router-dom";
const Portal = () => {
    return (
        <div className="container-fluid p-0 w-100 portal">
            <nav className="navbar navbar-expand-md bg-dark navbar-dark w-100 ">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        Phoenix Labs
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className=" collapse navbar-collapse justify-content-end " id="collapsibleNavbar">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link className="nav-link text-white" to="/portal/virtualservers">
                                    Virtual Servers
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-white" to="/portal/datacenter">
                                    Data Centers
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="d-block d-sm-flex align-items-center h-75 mt-4">
                <section className="w-sm-50 w-100">
                    <div className="container ">
                        <Link to="/portal/virtualservers">
                            <div className="row justify-content-center">
                                <div className="col-md-6 text-center border text-white w-50 shadow-lg rounded crd p-3 mb-4 mb-sm-0">
                                    <h2 className="my-3">Virtual Servers</h2>
                                    <p>
                                        Our virtual servers provide our global partners with efficient computing resources with easy deployment and scalability.
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </section>
                <section className="w-100 w-sm-50">
                    <div className="container">
                        <Link to="/portal/datacenter">
                            <div className="row justify-content-center">
                                <div className="col-md-6 text-center border text-white w-50 rounded shadow crd p-3 mt-4 mt-sm-0">
                                    <h2 className="my-3 text-light">Data Centers</h2>
                                    Our Data Centers offers our global partners secure storage and reliable connectivity for Critical Applications and Cloud
                                    Services.
                                </div>
                            </div>
                        </Link>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Portal;
