import img1 from "../images/logo.png";
const Home = () => {
    return (
        <div>
            <div className="main_div container-fluid p-0 m-0 ">
                <nav className="navbar navbar-expand-lg navbar-light bg-ligh fixed-tp text-white">
                    <div className="container-fluid text-white">
                        <div className="text-center ms-0 ms-sm-4 ">
                            <img style={{ height: "3rem" }} src={img1} alt="Logo" />
                            <div>
                                <span>Phoenix</span>
                                <span className="ms-1" style={{ color: "#ffe500" }}>
                                    Labs
                                </span>
                            </div>
                        </div>
                        <button
                            className="navbar-toggler text-white"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon text-white" />
                        </button>
                        <div className="collapse navbar-collapse " id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg">
                                <li className="nav-item">
                                    <a className="nav-link active text-white fs-5" aria-current="page" href="/map">
                                        View Map
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active text-white fs-5" href="/login">
                                        Login
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active text-white fs-5" href="/register">
                                        Register
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                <main>
                    <div className="content w-75 w-md-50 ">
                        <h1>Our Global Network</h1>
                        <p className="first_para">Phoenix Labs operates a high-growth platform that enables our global network to innovate and execute</p>
                        <p className="second_para">We build, expand, and improve upon world-class Ai, Crypto, and Web/Mobile application initiatives.</p>
                        <a href="/map" className="btn btn-light ">
                            View Map
                        </a>
                    </div>
                </main>
            </div>
            <footer className="container-fluid">
                <div className="row p-4">
                    <div className="col col-sm-2">
                        <h3 className="text-white ">Business</h3>
                        <ul>
                            <li className="mb-1">
                                <a href="/">Technology</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Steel</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Automotive</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Consumer & Retail</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Infrastructure</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Financial Services</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Aerospace & Defence</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Tourism & Travel</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Telecom & Media</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Trading & Investment</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col col-sm-2">
                        <h3 className="text-white ">Community</h3>
                        <ul>
                            <li className="mb-1">
                                <a href="/">Health</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Education</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Empowerment</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Environment</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col col-sm-2">
                        <h3 className="text-white ">About</h3>
                        <ul>
                            <li className="mb-1">
                                <a href="/">The Phoenix group</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Phoenix Sons</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Values and Purpose</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Leadership</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Heritage</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Sustainability</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Sponsorships</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Investors</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Phoenix Code of Conduct</a>
                            </li>
                            <li className="mb-1">
                                <a href="/">Technology</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col col-sm-2 ">
                        <h3 className="text-white ">Others</h3>
                        <ul>
                            <li>
                                <a href="/" className="content d-block">
                                    Newsroom
                                </a>
                            </li>
                            <li>
                                <a href="/" className="content d-block">
                                    Careers
                                </a>
                            </li>
                            <li>
                                <a href="/" className="content d-block">
                                    Jobs
                                </a>
                            </li>
                            <li>
                                <a href="/" className="content d-block">
                                    Equal Opportunity
                                </a>
                            </li>
                            <li>
                                <a href="/" className="content d-block">
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a href="/" className="content d-block">
                                    Legal Disclaimer
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col col-sm-4 newsletter">
                        <h3 className="text-white">Newsletter</h3>
                        <form className="pb-1 mb-5 mt-4 d-flex align-items-center justify-content-center">
                            <i className="bi bi-envelope"></i>
                            <input className="w-100 ps-2 text-white bg-transparent " type="email" placeholder="Enter your email id" required />
                            <button type="submit">
                                <i className="bi bi-arrow-right text-white"></i>
                            </button>
                        </form>
                        <div className="d-flex gap-4 justify-content-center justify-content-sm-start " style={{ cursor: "pointer" }}>
                            <i className="bi bi-facebook"></i>
                            <i className="bi bi-linkedin"></i>
                            <i className="bi bi-twitter"></i>
                            <i className="bi bi-youtube"></i>
                            <i className="bi bi-instagram"></i>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Home;
