import React from "react";

const HiddenItemsPanel = ({ brightness, setBrightness, isHiddenIconsVisible }) => {
    const handleBrightnessChange = (event) => {
        const newBrightness = event.target.value;
        setBrightness(newBrightness);
    };

    return (
        <div
            className={`${isHiddenIconsVisible ? "d-block" : "d-none"}`}
            style={{
                height: "220px",
                width: "189px",
                backgroundColor: "#222",
                position: "absolute",
                right: "60px",
                bottom: "5vh",
                zIndex: "100",
                boxShadow: " 0px 0px 2px #222",
            }}
        >
            <div className="w-100 d-flex justify-content-center align-items-center p-3" style={{ height: "30px", borderBottom: "1px solid #555" }}>
                <i className="bi bi-brightness-high text-light pe-3"></i>
                <input type="range" min="10" max="120" value={brightness} step="1" style={{ height: "5px" }} onChange={handleBrightnessChange} />
            </div>
            <div className="text-light text-center w-100">More Items to be added</div>
        </div>
    );
};

export default HiddenItemsPanel;
