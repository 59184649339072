import "./Apis.css";
const APIs = () => {
    return (
        <div className="apis-page">
            <nav className="navbar navbar-expand-md bg-dark navbar-dark ">
                <div className="container-fluid p-4">
                    <a className="navbar-brand" href="/">
                        Phoenix Labs
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end " id="collapsibleNavbar">
                        <ul className="navbar-nav ml-auto gap-4">
                            <li className="nav-item ">
                                <a className="nav-link text-white" href="/portal/datacenter/virtualdesktop">
                                    Access AI Services and Functionality
                                </a>
                            </li>
                            <li className="nav-item ">
                                <a className="nav-link text-white" href="/portal/datacenter/webaccess">
                                    Integrate AI with other Software
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="apis-content">
                <div className="container">
                    <div className="row justify-content-center align-items-center h-100">
                        <div className="col-md-8 col-lg-12">
                            <div className="card shadow">
                                <div className="card-body">
                                    <h2 className="card-title text-center mb-4">APIs</h2>
                                    <p>
                                        For developers looking to integrate AI capabilities into their applications, our APIs offer a seamless solution. Access
                                        AI services and functionality programmatically, integrating AI algorithms into your software products. Our
                                        well-documented and developer-friendly APIs allow for smooth integration, ensuring you can extend your applications with
                                        cutting-edge AI capabilities without the need for extensive AI expertise.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default APIs;
