import "./Network.css";
const Network = () => {
    return (
        <div className="web-access">
            <nav className="navbar navbar-expand-md bg-dark navbar-dark ">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        Phoenix Labs
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item ">
                                <a className="nav-link text-white" href="/portal/datacenter/webaccess">
                                    Secure authentication protocols and encryption
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="web-access-content">
                <div className="container">
                    <div className="row justify-content-center mt-4">
                        <div className="col-md-8 col-lg-12">
                            <div className="card text-light shadow" style={{ backgroundColor: "rgba(0,0,100,0.3)" }}>
                                <div className="card-body">
                                    <h2 className="card-title text-center mb-2">Network</h2>
                                    <h4 className="card-title text-center mb-3 " style={{ color: "rgba(255,255,255,0.9)" }}>
                                        High-Speed Connectivity for Optimal Performance
                                    </h4>
                                    <p className="text-center">
                                        The Network sub-page focuses on providing high-speed and reliable connectivity, ensuring optimal performance for your AI
                                        applications. Our Virtual Servers are backed by a cutting-edge network infrastructure, minimizing latency and
                                        facilitating seamless data transfers between your local environment and our Data Center. Experience lightning-fast
                                        communication with our AI resources, allowing you to iterate, experiment, and analyze AI models with maximum efficiency.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Network;
