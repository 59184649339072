import "./Diagnostics.css";
const Diagnostics = () => {
    return (
        <div className="diagnostics-page">
            <nav className="navbar navbar-expand-md bg-dark navbar-dark ">
                <div className="container-fluid p-4">
                    <a className="navbar-brand" href="/">
                        Phoenix Labs
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end " id="collapsibleNavbar">
                        <ul className="navbar-nav ml-auto gap-4">
                            <li className="nav-item ">
                                <a className="nav-link text-white" href="/portal/datacenter/virtualdesktop">
                                    Monitoring and Optimizing AI Applications
                                </a>
                            </li>
                            <li className="nav-item ">
                                <a className="nav-link text-white" href="/portal/datacenter/webaccess">
                                    Realtime insights into performance
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="diagnostics-content">
                <div className="container">
                    <div className="row justify-content-center align-items-center h-100">
                        <div className="col-md-8 col-lg-12">
                            <div className="card shadow text-light" style={{ backgroundColor: "rgba(0,0,150,0.3)" }}>
                                <div className="card-body">
                                    <h2 className="card-title text-center mb-4">Diagnostics</h2>
                                    <p>
                                        We understand the importance of monitoring and optimizing AI applications. Our Diagnostics feature provides real-time
                                        insights into the performance and health of your AI projects. Keep track of resource utilization, identify bottlenecks,
                                        and optimize your AI algorithms with ease. Diagnose issues, get performance metrics, and streamline your AI research
                                        process for superior results.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Diagnostics;
