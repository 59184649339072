import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import { signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore/lite";
import { auth, dbDatabase } from "../../firebase";
import faceIO from "@faceio/fiojs";
import HandleError from "./handleFaceioError";
import LoadingSpinner from "../Helpers/LoadingSpinner";

const faceio = new faceIO(process.env.REACT_APP_FACEIO_KEY);

const Login = ({ headQuarters }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [location, setLocation] = useState("Juba");
    const [password, setPassword] = useState("");
    const [viewPass, setViewPass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [displayFacialButton, setDisplayFacialButton] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        faceio.restartSession();
        const userDoc = doc(dbDatabase, "users", email);
        const userSnap = await getDoc(userDoc);
        if (userSnap.exists()) {
            const storedLocation = userSnap.data().location;
            if (storedLocation === location) {
                await signInWithEmailAndPassword(auth, email, password)
                    .then(() => {
                        message.success("Details Varified! Now Varify Your Facial ID");
                        setDisplayFacialButton(true);
                        setTimeout(() => {
                            alert("If you face any problem while verifying your face ID, then refresh the page and try again.");
                        }, 1000);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        message.error("User email or password wrong!");
                    });
            } else {
                message.error("Entered location is Wrong!");
            }
        } else {
            setIsLoading(false);
            message.error("You are not registered properly. Please register again.");
        }
        setIsLoading(false);
    };

    function authenticateUser(e) {
        e.preventDefault();
        faceio
            .authenticate({
                locale: "auto",
            })
            .then(() => {
                message.success("Success, user identified");
                localStorage.setItem("user_phoenixlab", email);
                navigate("/portal");
            })
            .catch((code) => {
                const warning = HandleError(code);
                message.error(warning);
                faceio.restartSession();
            });
    }
    return (
        <div className="container-fluid login d-flex align-items-center justify-content-center">
            <div className="col-10 col-sm-10 col-md-6 h-75 d-flex text-white login-shadow rounded">
                <div className="text-side h-100 text-center d-none d-sm-flex align-items-center img-bg rounded-start p-5 ">
                    <div>
                        <h1 className="text-start">
                            WELCOME <br /> BACK!
                        </h1>
                        <p className="m-0 text-start mt-4">
                            Enter your credentials to
                            <br />
                            access your account
                        </p>
                    </div>
                </div>
                <div className="login-side h-100 bg-dark rounded-end p-2">
                    <h2 className="text-center login-head">LOGIN</h2>
                    <form className="p-2 h-75" onSubmit={(e) => handleSubmit(e)}>
                        <div className="mt-2">
                            <div className="input-email mb-2">
                                <i className="bi bi-envelope"></i>
                                <input
                                    className="w-100 bg-transparent p-2 text-white"
                                    type="email"
                                    name="email"
                                    id="email"
                                    autoComplete="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="input-location mb-2">
                                <i className="bi bi-geo-alt-fill"></i>
                                <select
                                    name="location"
                                    id="location"
                                    className="w-100 bg-transparent text-white p-2 "
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                >
                                    <optgroup className="bg-dark">
                                        {headQuarters?.map((headQuarter, i) => {
                                            return (
                                                <option className="" value={headQuarter.data.city} key={i}>
                                                    {headQuarter.data.city}
                                                </option>
                                            );
                                        })}
                                    </optgroup>
                                </select>
                            </div>
                            <div className="input-password mb-2">
                                {viewPass ? (
                                    <i className="bi bi-eye-slash-fill" onClick={() => setViewPass(!viewPass)}></i>
                                ) : (
                                    <i className="bi bi-eye-fill" onClick={() => setViewPass(!viewPass)}></i>
                                )}

                                <input
                                    className="w-100 bg-transparent p-2 text-white"
                                    type={`${viewPass ? "text" : "password"}`}
                                    name="password"
                                    id="password"
                                    placeholder="Password"
                                    value={password}
                                    autoComplete="current-password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="signup p-2 mb-2">
                            <p className="m-0 ">
                                Don't have an account?
                                <Link className="ps-1" to="/register">
                                    Sign Up
                                </Link>
                            </p>
                        </div>
                        <div className="w-75 m-auto d-flex justify-content-between">
                            <Link className="text-center button " to="/">
                                Cancel
                            </Link>
                            {isLoading ? (
                                <LoadingSpinner />
                            ) : (
                                <button type="submit" className="text-center button">
                                    Login
                                </button>
                            )}
                        </div>
                        {displayFacialButton && (
                            <div className="h-25 mt-5">
                                <button onClick={authenticateUser} className="text-center button w-100 h-100">
                                    Click Here For Facial Varification
                                </button>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
