import "./GateWay.css";
const Gateway = () => {
    return (
        <div className="gateway-page ">
            <nav className="navbar navbar-expand-md  bg-dark navbar-dark ">
                <div className="container-fluid p-4">
                    <a className="navbar-brand" href="/">
                        Phoenix Labs
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
                        <ul className="navbar-nav ml-auto gap-5">
                            <li className="nav-item ">
                                <a className="nav-link text-white" href="/portal/datacenter/virtualdesktop">
                                    Facilitates data transfer
                                </a>
                            </li>
                            <li className="nav-item ">
                                <a className="nav-link text-white" href="/portal/datacenter/webaccess">
                                    Seamlessly move data and AI Models to and from the platform
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="gateway-content">
                <div className="container">
                    <div className="row justify-content-center align-items-center h-100">
                        <div className="col-md-8 col-lg-12">
                            <div className="card shadow">
                                <div className="card-body">
                                    <h2 className="card-title text-center mb-4">The Gateway</h2>
                                    <p>
                                        The Gateway serves as the bridge between your local environment and the Phoenix Labs Data Center. It facilitates secure
                                        and reliable data transfers, enabling you to seamlessly move your data and AI models to and from our infrastructure. Our
                                        Gateway ensures minimal latency and maximum throughput, allowing you to harness the full potential of our AI resources
                                        with efficiency and speed.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Gateway;
