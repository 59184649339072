import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Map from "./Components/Map/Map";
import Login from "./Components/Pages/Login";
import Home from "./Components/Pages/Home";
import Register from "./Components/Pages/Register";
import Portal from "./Components/Pages/Portal";
import VirtualServer from "./Components/Pages/Virtualservers/VirtualServer";
import DataCenter from "./Components/Pages/DataCenter/DataCenter";
import VirtualDesktop from "./Components/Pages/IonosphereVirtualDesktop/VirtualDesktop";
import WebAccess from "./Components/Pages/WebAccess/WebAccess";
import Gateway from "./Components/Pages/Gateway/Gateway";
import Broker from "./Components/Pages/Broker/Broker";
import Diagnostics from "./Components/Pages/Diagnostics/Diagnostics";
import APIs from "./Components/Pages/APIs/APIs";
import Network from "./Components/Pages/Network/Network";
import Software from "./Components/Pages/Software/Software";
import Storage from "./Components/Pages/Storage/Storage";
import { collection, getDocs } from "firebase/firestore/lite";
import { dbDatabase } from "./firebase";
import { message } from "antd";
function App() {
    const [loading, setLoading] = useState(false);
    const [headQuarters, setHeadQuaters] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const temp = [];
            try {
                const snapshot = await getDocs(collection(dbDatabase, "dataCenters"));
                snapshot.forEach((doc) => {
                    temp.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });
                setHeadQuaters(temp);
                console.log("Data Centers Fetched Successfully");
                setLoading(false);
            } catch (error) {
                message.error("Unable to fetch datacenters information. Please check your internet and try again");
                console.log(error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    return (
        <Routes>
            <Route path="/map" element={<Map headQuarters={headQuarters} loading={loading} />} />
            <Route
                path="/login"
                element={
                    <ProtectLoginRegistration>
                        <Login headQuarters={headQuarters} />
                    </ProtectLoginRegistration>
                }
            />
            <Route
                path="/register"
                element={
                    <ProtectLoginRegistration>
                        <Register headQuarters={headQuarters} />
                    </ProtectLoginRegistration>
                }
            />

            <Route
                path="/portal"
                element={
                    <ProtectedRoutes>
                        <Portal />
                    </ProtectedRoutes>
                }
            />
            <Route
                path="/portal/virtualservers"
                element={
                    <ProtectedRoutes>
                        <VirtualServer />
                    </ProtectedRoutes>
                }
            />
            <Route
                path="/portal/datacenter"
                element={
                    <ProtectedRoutes>
                        <DataCenter />
                    </ProtectedRoutes>
                }
            />

            <Route
                path="/portal/virtualservers/network"
                element={
                    <ProtectedRoutes>
                        <Network />
                    </ProtectedRoutes>
                }
            />
            <Route
                path="/portal/virtualservers/virtualdesktop"
                element={
                    <ProtectedRoutes>
                        <VirtualDesktop />
                    </ProtectedRoutes>
                }
            />
            <Route
                path="/portal/virtualservers/software"
                element={
                    <ProtectedRoutes>
                        <Software />
                    </ProtectedRoutes>
                }
            />
            <Route
                path="/portal/virtualservers/storage"
                element={
                    <ProtectedRoutes>
                        <Storage />
                    </ProtectedRoutes>
                }
            />
            <Route
                path="/portal/datacenter/virtualdesktop"
                element={
                    <ProtectedRoutes>
                        <VirtualDesktop />
                    </ProtectedRoutes>
                }
            />
            <Route
                path="/portal/datacenter/webaccess"
                element={
                    <ProtectedRoutes>
                        <WebAccess />
                    </ProtectedRoutes>
                }
            />
            <Route
                path="/portal/datacenter/gateway"
                element={
                    <ProtectedRoutes>
                        <Gateway />
                    </ProtectedRoutes>
                }
            />
            <Route
                path="/portal/datacenter/broker"
                element={
                    <ProtectedRoutes>
                        <Broker />
                    </ProtectedRoutes>
                }
            />
            <Route
                path="/portal/datacenter/diagnostics"
                element={
                    <ProtectedRoutes>
                        <Diagnostics />
                    </ProtectedRoutes>
                }
            />
            <Route
                path="/portal/datacenter/apis"
                element={
                    <ProtectedRoutes>
                        <APIs />
                    </ProtectedRoutes>
                }
            />
            <Route path="/" element={<Home />} />
        </Routes>
    );
}
export function ProtectedRoutes(props) {
    if (localStorage.getItem("user_phoenixlab")) {
        return props.children;
    } else {
        return <Navigate to="/login" />;
    }
}
export function ProtectLoginRegistration(props) {
    if (localStorage.getItem("user_phoenixlab")) {
        return <Navigate to="/portal" />;
    } else {
        return props.children;
    }
}
export default App;
