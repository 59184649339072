import "./Virtualserver.css";
const VirtualServer = () => {
    return (
        <div className="virtualserver text-white bg-dark">
            <nav className="navbar navbar-expand-md bg-primary navbar-dark ">
                <div className="container">
                    <a className="navbar-brand" href="/portal/virtualservers">
                        Phoenix Labs
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link text-white" href="/portal/virtualservers/virtualdesktop">
                                    Desktop
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="/portal/virtualservers/software">
                                    Software
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="/portal/virtualservers/storage">
                                    Storage
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="/portal/virtualservers/network">
                                    Network
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="container-fluid">
                <div className="virtualserver_header">
                    <h1 className="heading">Virtual Servers: Unleash the Power of AI with Flexible Virtualization</h1>
                    <p>
                        At Phoenix Labs, we understand that AI research demands unparalleled computing resources and seamless access to cutting-edge technology.
                        Our Virtual Servers provide a dynamic and scalable solution, empowering you to accelerate your AI exploration without the constraints of
                        physical hardware.
                    </p>
                </div>
                <div className="sub-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6" id="desktop">
                                <a href="/portal/virtualservers/virtualdesktop" className="card " style={{ cursor: "pointer", textAlign: "justify" }}>
                                    <div className="card-body">
                                        <h2 className="text-center">Desktop: A Virtual AI Workspace Like No Other</h2>
                                        <p className="p-2">
                                            The Desktop sub-page is your personal AI haven within the Ionosphere Virtual Desktop. With our Virtual Desktop
                                            solution, you can access a fully personalized workspace tailored to your unique AI research needs. Enjoy the freedom
                                            to customize your environment, install the software you require, and configure settings to match your preferences.
                                            Collaborate effortlessly with your team members, share insights, and collectively drive innovation through a
                                            centralized and intuitive Virtual Desktop interface.
                                        </p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6" id="software">
                                <a href="/portal/virtualservers/software" className="card h-100" style={{ cursor: "pointer", textAlign: "justify" }}>
                                    <div className="card-body">
                                        <h2 className="text-center">Software: AI Tools at Your Fingertips</h2>
                                        <p className="p-2">
                                            The Software sub-page offers an extensive array of pre-installed AI tools and frameworks, ensuring you have the
                                            necessary resources to jump-start your AI projects instantly. From popular machine learning libraries to specialized
                                            AI development environments, our Virtual Servers come equipped with a wide range of software resources. Save time on
                                            setup and configuration, and dive straight into your AI research with powerful and up-to-date software tools readily
                                            available within your Virtual AI environment.
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sub-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6" id="storage">
                                <a href="/portal/virtualservers/storage" className="card h-100" style={{ cursor: "pointer", textAlign: "justify" }}>
                                    <div className="card h-100" style={{ cursor: "pointer", textAlign: "justify" }}>
                                        <div className="card-body">
                                            <h2 className="text-center">Storage: Secure and Scalable Data Management</h2>
                                            <p className="p-2">
                                                Our Storage sub-page boasts a robust and scalable data management system designed to accommodate vast amounts of
                                                AI data. With high-performance storage solutions, you can efficiently store and access datasets critical to your
                                                research. Rest assured that your data is secure and protected with our data center's advanced security measures
                                                and compliance protocols. Seamlessly integrate your data pipelines, manage version control, and handle
                                                large-scale AI data projects confidently with Phoenix Labs' Virtual Servers.
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6" id="network">
                                <div className="card h-100" style={{ cursor: "pointer", textAlign: "justify" }}>
                                    <div className="card-body">
                                        <h2 className="text-center">Network: High-Speed Connectivity for Optimal Performance</h2>
                                        <p className="p-2">
                                            The Network sub-page focuses on providing high-speed and reliable connectivity, ensuring optimal performance for
                                            your AI applications. Our Virtual Servers are backed by a cutting-edge network infrastructure, minimizing latency
                                            and facilitating seamless data transfers between your local environment and our Data Center. Experience
                                            lightning-fast communication with our AI resources, allowing you to iterate, experiment, and analyze AI models with
                                            maximum efficiency.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="container-fluid mt-4 p-5 text-white">
                <h2 className="text-center">Phoenix Labs' Virtual Servers</h2>
                <p className="text-center">
                    At Phoenix Labs, we recognize that AI research demands flexibility, power, and convenience. With our Virtual Servers and its sub-pages
                    Desktop, Software, Storage, and Network. We offer a comprehensive virtualization solution tailored to your AI exploration needs. Unleash the
                    full potential of AI with our state of the art infrastructure and empower your team to redefine what's possible in the world of artificial
                    intelligence. Take your AI research to new heights with Phoenix Labs' Virtual Servers today.
                </p>
            </footer>
        </div>
    );
};

export default VirtualServer;
