import React from "react";

const ContextMenu = ({ handleOpenFolder, handleRenameFolder, handleDeleteFolder, selectedFolder }) => {
    return (
        <div className="context-menu" style={{ fontSize: "0.8rem", left: "100px", top: "300px" }}>
            <div className="option  pe-2 ps-2 p-1" style={{ cursor: "pointer" }}>
                <div onClick={() => handleOpenFolder(selectedFolder)}>Open</div>
            </div>
            <div className="option  pe-2 ps-2 p-1" style={{ cursor: "pointer" }}>
                <div onClick={() => handleRenameFolder(selectedFolder)}>Rename</div>
            </div>
            <div className="pe-2 ps-2 p-1" style={{ cursor: "pointer" }}>
                <div onClick={() => handleDeleteFolder(selectedFolder)}>Delete</div>
            </div>
        </div>
    );
};

export default ContextMenu;
