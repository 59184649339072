import React, { useState, useEffect } from "react";

const Battery = () => {
    //Code for Fetching & Updating Battery Level
    const [batteryLevel, setBatteryLevel] = useState(null);
    const [isCharging, setIsCharging] = useState(false);

    useEffect(() => {
        // Function to update battery level and charging status
        const updateBatteryStatus = async () => {
            try {
                const battery = await navigator.getBattery();
                setBatteryLevel(Math.floor(battery.level * 100));
                setIsCharging(battery.charging);
            } catch (error) {
                console.error("Error accessing battery status:", error);
            }
        };

        // Check if the browser supports the Battery Status API
        if ("getBattery" in navigator) {
            // Update battery level and charging status immediately
            updateBatteryStatus();

            // Set up an event listener to update battery status when it changes
            navigator.getBattery().then((battery) => {
                battery.addEventListener("levelchange", updateBatteryStatus);
                battery.addEventListener("chargingchange", updateBatteryStatus);
            });

            // Clean up the event listeners when the component is unmounted
            return () => {
                navigator.getBattery().then((battery) => {
                    battery.removeEventListener("levelchange", updateBatteryStatus);
                    battery.removeEventListener("chargingchange", updateBatteryStatus);
                });
            };
        } else {
            console.warn("Battery Status API not supported in this browser.");
        }
    }, []);
    const getBatteryIconClass = (batteryLevel, isCharging) => {
        if (isCharging) {
            return "bi-battery-charging";
        } else {
            if (batteryLevel >= 90) {
                return "bi-battery-full";
            } else if (batteryLevel >= 20) {
                return "bi-battery-half";
            } else {
                return "bi-battery";
            }
        }
    };
    // Battery Level Indicator Code Ended
    return (
        <div className="battery pe-2 ps-2 h-100 d-flex align-items-center" style={{ cursor: "default" }}>
            {batteryLevel !== null ? (
                <div className="d-flex align-items-center">
                    <div style={{ fontSize: "0.8rem" }}>{batteryLevel}%</div>
                    <i className={`bi ${getBatteryIconClass(batteryLevel, isCharging)} ps-1`}></i>
                </div>
            ) : (
                <p>Battery status is not available.</p>
            )}
        </div>
    );
};

export default Battery;
