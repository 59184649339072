import React, { useState } from "react";
import "./VirtualDesktop.css";
import Logo from "../../images/logo.png";
import mspaint from "../../images/mspaint.png";
import vscode from "../../images/vscode.png";
import filemanager from "../../images/filemanager.png";
import linphone from "../../images/linphone.png";
import FolderIcon from "./Components/FolderIcon";
import FolderApp from "./Components/FolderApp";
import ContextMenu from "./Components/ContextMenu";
import FullScreen from "./Components/FullScreen";
import Battery from "./Components/Battery";
import VsCode from "./Components/VsCode";
import MsPaint from "./Components/MsPaint";
import FileManager from "./Components/FileManager";
import SoftwareIcon from "./Components/SoftwareIcon";
import RenameCard from "./Components/RenameCard";
import NewFolderCard from "./Components/NewFolderCard";
import RightClickMenu from "./Components/RightClickMenu";
import Wifi from "./Components/Wifi";
import Time from "./Components/Time";
import HiddenItemsPanel from "./Components/HiddenItemsPanel";
import Chevron from "./Components/Chevron";
import Linphone from "./Components/Linphone";
const VirtualDesktop = () => {
    //Code for Draggable Softwares Started
    const [isVsCodeOpened, setIsVsCodeOpened] = useState(false);
    const [isMsPaintOpened, setIsMsPaintOpened] = useState(false);
    const [isFileManagerOpened, setIsFileManagerOpened] = useState(false);
    const [isLinphoneOpened, setIsLinphoneOpened] = useState(false);

    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
    const handleContextMenu = (event) => {
        event.preventDefault();
        const mouseX = event.clientX;
        const mouseY = event.clientY;
        setContextMenuPosition({ x: mouseX, y: mouseY });
        setContextMenuVisible(true);
    };

    // Dummy Data of folders and softwares
    const [folders, setFolders] = useState([
        { id: "1", folderName: "Simulators", data: {} },
        { id: "2", folderName: "Data Analyzer", data: {} },
        { id: "3", folderName: "Test Our AI Models", data: {} },
    ]);

    const softwares = [
        { id: 1, software_name: "Vs Code", software_icon: vscode, software_class: "vscode", openSoftware: isVsCodeOpened, setOpenSoftware: setIsVsCodeOpened },
        {
            id: 2,
            software_name: "Ms Paint",
            software_icon: mspaint,
            software_class: "mspaint",
            openSoftware: isMsPaintOpened,
            setOpenSoftware: setIsMsPaintOpened,
        },
        {
            id: 3,
            software_name: "File Manager",
            software_icon: filemanager,
            software_class: "filemanager",
            openSoftware: isFileManagerOpened,
            setOpenSoftware: setIsFileManagerOpened,
        },
        {
            id: 4,
            software_name: "Phoenix Uplink",
            software_icon: linphone,
            software_class: "linphone",
            openSoftware: isLinphoneOpened,
            setOpenSoftware: setIsLinphoneOpened,
        },
    ];

    // 3 Dots code when we hover on any folder
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [isFolderOpened, setIsFolderOpened] = useState(false);
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [isInputBarVisibleForRename, setIsInputBarVisibleForRename] = useState(false);
    const [isInputBarVisibleForNewFolder, setIsInputBarVisibleForNewFolder] = useState(false);

    const handleContextMenuOpen = (event, folder) => {
        event.preventDefault();
        setSelectedFolder(folder);
        setShowContextMenu(!showContextMenu);
    };

    const handleOpenFolder = () => {
        setShowContextMenu(false);
        setIsFolderOpened(false);
        setIsFolderOpened(true);
    };

    const handleRenameFolder = () => {
        setShowContextMenu(false);
        setIsInputBarVisibleForRename(true);
    };

    const handleDeleteFolder = (folderToDelete) => {
        const updatedFolders = folders.filter((folder) => folder.id !== folderToDelete.id);
        setFolders(updatedFolders);
        setSelectedFolder(null);
        setShowContextMenu(false);
    };
    // Code for brightness
    const [brightness, setBrightness] = useState(100);
    const brightnessStyle = { filter: `brightness(${brightness}%)` };
    // Code to show hidden items panel
    const [isHiddenIconsVisible, setIsHiddenIconsVisible] = useState(false);

    return (
        <div className="desktop " style={brightnessStyle}>
            {/* Background wallpaper Layer */}
            <div className="desktop-background "></div>

            {/* JMedia Logo Layer */}
            <div className="logo overflow-hidden d-flex justify-content-center align-items-center">
                <div>
                    <div className="text-center">
                        <img src={Logo} alt="" />
                    </div>
                    <span className="text-white fs-4">Phoenix</span>
                    <span className="ms-1 fs-4" style={{ color: "#ffe500" }}>
                        Labs
                    </span>
                </div>
            </div>
            {/* JMedia Logo Layer  Ended */}

            {/* Main Desktop Layer Started */}
            <div className="desktop overflow-hidden">
                {/* Main Screen from Top to Taskbar div */}
                <div className="mainscreen overflow-hidden" onContextMenu={handleContextMenu}>
                    <FullScreen />

                    {/* Code to display all the folders  */}
                    <div className="folders-div h-100 w-100 container-fluid pt-3">
                        <div className="row">
                            {folders?.map((folder, i) => (
                                <FolderIcon
                                    key={i}
                                    handleContextMenuOpen={handleContextMenuOpen}
                                    handleOpenFolder={handleOpenFolder}
                                    folder={folder}
                                    selectedFolder={selectedFolder}
                                />
                            ))}
                        </div>
                    </div>

                    <FolderApp isFolderOpened={isFolderOpened} selectedFolder={selectedFolder} setIsFolderOpened={setIsFolderOpened} />

                    <FileManager isFileManagerOpened={isFileManagerOpened} setIsFileManagerOpened={setIsFileManagerOpened} />

                    <MsPaint isMsPaintOpened={isMsPaintOpened} setIsMsPaintOpened={setIsMsPaintOpened} />

                    <VsCode setIsVsCodeOpened={setIsVsCodeOpened} isVsCodeOpened={isVsCodeOpened} />
                    <Linphone setIsLinphoneOpened={setIsLinphoneOpened} isLinphoneOpened={isLinphoneOpened} />
                    <HiddenItemsPanel isHiddenIconsVisible={isHiddenIconsVisible} brightness={brightness} setBrightness={setBrightness} />

                    {contextMenuVisible && (
                        <RightClickMenu
                            setContextMenuVisible={setContextMenuVisible}
                            contextMenuPosition={contextMenuPosition}
                            setIsInputBarVisibleForNewFolder={setIsInputBarVisibleForNewFolder}
                        />
                    )}

                    {showContextMenu && selectedFolder && (
                        <ContextMenu
                            handleOpenFolder={handleOpenFolder}
                            handleRenameFolder={handleRenameFolder}
                            handleDeleteFolder={handleDeleteFolder}
                            selectedFolder={selectedFolder}
                        />
                    )}

                    {isInputBarVisibleForRename && selectedFolder && (
                        <RenameCard setFolders={setFolders} selectedFolder={selectedFolder} setIsInputBarVisibleForRename={setIsInputBarVisibleForRename} />
                    )}

                    {isInputBarVisibleForNewFolder && (
                        <NewFolderCard setFolders={setFolders} setIsInputBarVisibleForNewFolder={setIsInputBarVisibleForNewFolder} />
                    )}
                </div>
                {/* Main Screen from Top to Taskbar div Ended*/}
                {/* Taskbar Code Started */}
                <div className="taskbar text-white d-flex align-items-center">
                    <div className="icons d-flex h-100 w-100">
                        <div className="left-icons " style={{ width: "25%" }}>
                            <i className="bi bi-windows  ps-3 pe-3"></i>
                            <input type="text" placeholder="Search" className="bg-transparent searchbar" />
                        </div>

                        <div className="middle-icons d-flex justify-content-center align-items-center" style={{ width: "50%" }}>
                            {softwares?.map((software, i) => (
                                <SoftwareIcon
                                    key={i}
                                    icon={software.software_icon}
                                    openSoftware={software.openSoftware}
                                    setOpenSoftware={software.setOpenSoftware}
                                    upperClass={software.software_class}
                                />
                            ))}
                        </div>

                        <div className="right-icons d-flex justify-content-end align-items-center" style={{ width: "25%" }}>
                            <Chevron isHiddenIconsVisible={isHiddenIconsVisible} setIsHiddenIconsVisible={setIsHiddenIconsVisible} />
                            <Battery />
                            <Wifi />
                            <Time />
                        </div>
                    </div>
                </div>
                {/* Taskbar Code Ended */}
            </div>
        </div>
    );
};

export default VirtualDesktop;
