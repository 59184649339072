export default function HandleError(errCode) {
    switch (errCode) {
        case 1:
            return "Access to the Camera stream was denied by the end user";

        case 2:
            return "No faces were detected during the enroll or authentication process";

        case 3:
            return "Unrecognized face on this application's Facial Index";

        case 4:
            return "Two or more faces were detected during the scan process";

        case 5:
            return "User enrolled previously (facial features already recorded). Cannot enroll again!";

        case 6:
            return "Minors are not allowed to enroll on this application!";

        case 7:
            return "Presentation (Spoof) Attack (PAD) detected during the scan process";

        case 9:
            return "Calculated Facial Vectors of the user being enrolled do not matches";

        case 8:
            return "Wrong PIN code supplied by the user being authenticated";

        case 10:
            return "Server side error";

        case 11:
            return "Your application is not allowed to perform the requested operation (eg. Invalid ID, Blocked, Paused, etc.). Refer to the FACEIO Console for additional information";

        case 12:
            return "Terms & Conditions set out by FACEIO/host application rejected by the end user";

        case 13:
            return "The FACEIO Widget could not be (or is being) injected onto the client DOM";

        case 14:
            return "Client session expired. The first promise was already fulfilled but the host application failed to act accordingly";

        case 15:
            return "Ongoing operation timed out (eg, Camera access permission, ToS accept delay, Face not yet detected, Server Reply, etc.)";

        case 16:
            return "Widget instantiation requests exceeded for freemium applications. Does not apply for upgraded applications";

        case 17:
            return "Origin or Referer HTTP request header is empty or missing";

        case 18:
            return "Domain origin is forbidden from instantiating fio.js";

        case 19:
            return "Country ISO-3166-1 Code is forbidden from instantiating fio.js";

        case 20:
            return "Another authentication or enrollment session is in progress";

        case 21:
        default:
            return "Error while establishing network connection with the target FACEIO processing node";
    }
}
