import React from "react";

const File = ({ file, getFileExtension }) => {
    function formatBytes(bytes) {
        if (bytes < 1024) {
            return bytes + " bytes";
        } else if (bytes < 1024 * 1024) {
            return (bytes / 1024).toFixed(2) + " KB";
        } else if (bytes < 1024 * 1024 * 1024) {
            return (bytes / (1024 * 1024)).toFixed(2) + " MB";
        } else {
            return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
        }
    }
    function truncateFileName(fileName) {
        const parts = fileName.split(".");
        if (parts[0].length > 20) {
            return parts[0].substring(0, 20) + "...";
        }
        return parts[0];
    }
    const name = truncateFileName(file.item.caption);
    const fileType = getFileExtension(file.item.caption);
    const size = formatBytes(file.item.size);

    return (
        <div
            className="file w-100 d-flex justify-content-between align-items-center"
            style={{ height: "20px", fontSize: "0.6rem", borderBottom: "1px solid #555", color: "#eee" }}
        >
            <span className="ps-4" style={{ width: "40%" }}>
                {name}
            </span>
            <span className="ps-4 text-center" style={{ width: "30%" }}>
                {fileType}
            </span>
            <span className="ps-4 text-end pe-4" style={{ width: "30%" }}>
                {size}
            </span>
        </div>
    );
};

export default File;
