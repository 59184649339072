import "./Datacenter.css";
const DataCenter = () => {
    return (
        <div className="datacenter">
            <nav className="navbar navbar-expand-md bg-dark navbar-dark ">
                <div className="container-fluid p-4">
                    <a className="navbar-brand" href="/">
                        Phoenix Labs
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end " id="collapsibleNavbar">
                        <ul className="navbar-nav ml-auto gap-4">
                            <li className="nav-item ">
                                <a className="nav-link text-white" href="/portal/datacenter/virtualdesktop">
                                    Ionosphere Virtual Desktop
                                </a>
                            </li>
                            <li className="nav-item ">
                                <a className="nav-link text-white" href="/portal/datacenter/webaccess">
                                    Web Access
                                </a>
                            </li>
                            <li className="nav-item ">
                                <a className="nav-link text-white" href="/portal/datacenter/gateway">
                                    Gateway
                                </a>
                            </li>
                            <li className="nav-item ">
                                <a className="nav-link text-white" href="/portal/datacenter/broker">
                                    Broker
                                </a>
                            </li>
                            <li className="nav-item ">
                                <a className="nav-link text-white" href="/portal/datacenter/diagnostics">
                                    Diagnostics
                                </a>
                            </li>
                            <li className="nav-item ">
                                <a className="nav-link text-white" href="/portal/datacenter/apis">
                                    APIs
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div
                className="container mt-4 d-flex justify-content-center align-items-center p-4 w-sm-25 border rounded shadow"
                style={{ backgroundColor: "rgba(0,10,100,0.5)" }}
            >
                <div className="text-white text-center">
                    <h2>Data Center</h2>
                    <p>
                        At Phoenix Labs, our Data Center serves as the backbone of our AI research infrastructure. Our state-of-the-art data center houses
                        cutting-edge computing resources, advanced data storage solutions, and high-speed interconnects. This powerful setup enables us to
                        process vast amounts of data and run complex AI algorithms efficiently. We prioritize data security and ensure that all data is stored
                        in a safe and compliant manner, guaranteeing the privacy and integrity of our clients' valuable information.
                    </p>
                </div>
            </div>
            <footer className="container-fluid mt-sm-4 p-md-4 p-3 text-white">
                <h3 className="text-center">Virtual Servers</h3>
                <p className="text-justify">
                    Phoenix Labs is committed to pushing the boundaries of AI research and development. With our comprehensive suite of tools and services the
                    Data Center, Ionosphere Virtual Desktop, Web Access, Gateway, Broker, Diagnostics, and APIs we empower researchers, developers, and
                    businesses to unlock the true potential of artificial intelligence. Join us in this exciting journey and elevate your AI exploration with
                    Phoenix Labs today.
                </p>
            </footer>
        </div>
    );
};

export default DataCenter;
