import React, { useState } from "react";

const RenameCard = ({ setFolders, selectedFolder, setIsInputBarVisibleForRename }) => {
    const [renameFolderName, setRenameFolderName] = useState("");
    const handleRenameSubmit = () => {
        if (selectedFolder && renameFolderName.trim() !== "") {
            setFolders((prevFolders) => prevFolders.map((folder) => (folder.id === selectedFolder.id ? { ...folder, folderName: renameFolderName } : folder)));
            setRenameFolderName("");
            setIsInputBarVisibleForRename(false);
        }
    };
    return (
        <div
            className="position-absolute text-light text-center p-2"
            style={{ backgroundColor: "#222", fontSize: "0.8rem", width: "200px", top: "200px", left: "400px", zIndex: "100" }}
        >
            <div className="mb-2">
                Enter Folders New Name
                <i className="bi bi-x-lg ms-3" style={{ cursor: "pointer" }} onClick={() => setIsInputBarVisibleForRename(false)}></i>
            </div>
            <input
                type="text"
                className="rename w-100 bg-transparent rounded p-1 text-light"
                style={{ border: "1px solid #555" }}
                placeholder={selectedFolder.folderName}
                onChange={(e) => setRenameFolderName(e.target.value)}
            />
            <button className="btn btn-secondary ps-1 pe-1 p-0 mt-2" style={{ fontSize: "0.8rem" }} onClick={handleRenameSubmit}>
                Rename
            </button>
        </div>
    );
};

export default RenameCard;
