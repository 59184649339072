import React, { useState, useEffect } from "react";

const Time = () => {
    // Code to Fetch and Display Time & Date
    const [currentTime, setCurrentTime] = useState(new Date());
    const [is24HourFormat, setIs24HourFormat] = useState(true);
    // Function to update the current time every second
    useEffect(() => {
        const updateTime = () => {
            setCurrentTime(new Date());
        };

        // Set up an interval to update the time every second
        const interval = setInterval(updateTime, 1000);

        // Clean up the interval when the component is unmounted
        return () => {
            clearInterval(interval);
        };
    }, []);

    // Toggle between 24-hour and 12-hour time formats
    const toggleTimeFormat = () => {
        setIs24HourFormat((prev) => !prev);
    };

    // Time options for formatting
    const timeOptions = {
        hour12: !is24HourFormat,
        hour: "2-digit",
        minute: "2-digit",
    };
    // Time & Date Code Ended
    return (
        <div onClick={toggleTimeFormat} className="time me-2 pe-2 ps-2 h-100 d-flex align-items-center" style={{ cursor: "pointer", fontSize: "0.8rem" }}>
            {currentTime.toLocaleTimeString([], timeOptions)}
        </div>
    );
};

export default Time;
