import React, { useState, useEffect } from "react";
import folderIcon from "../../../images/foldericon.png";
const FolderApp = ({ isFolderOpened, selectedFolder, setIsFolderOpened }) => {
    const [folderDivPosition, setFolderDivPosition] = useState({ x: 400, y: 100 });
    const [isFolderDivDragging, setFolderDivIsDragging] = useState(false);
    const [initialFolderDivPosition, setFolderDivInitialPosition] = useState({ x: 0, y: 0 });
    const folderDivhandleMouseDown = (event) => {
        setFolderDivIsDragging(true);
        setFolderDivInitialPosition({
            x: event.clientX - folderDivPosition.x,
            y: event.clientY - folderDivPosition.y,
        });
    };

    const folderDivhandleMouseMove = (event) => {
        if (isFolderDivDragging) {
            setFolderDivPosition({
                x: event.clientX - initialFolderDivPosition.x,
                y: event.clientY - initialFolderDivPosition.y,
            });
        }
    };

    const folderDivhandleMouseUp = () => {
        setFolderDivIsDragging(false);
    };

    useEffect(() => {
        if (isFolderDivDragging) {
            document.addEventListener("mousemove", folderDivhandleMouseMove);
            document.addEventListener("mouseup", folderDivhandleMouseUp);
        } else {
            document.removeEventListener("mousemove", folderDivhandleMouseMove);
            document.removeEventListener("mouseup", folderDivhandleMouseUp);
        }

        return () => {
            document.removeEventListener("mousemove", folderDivhandleMouseMove);
            document.removeEventListener("mouseup", folderDivhandleMouseUp);
        };
    }, [isFolderDivDragging]);
    return (
        <div
            className={`drag folderDiv ${isFolderOpened ? "d-block" : "d-none"}`}
            style={{ left: folderDivPosition.x, top: folderDivPosition.y, userSelect: "none" }}
        >
            <div
                className="head d-flex justify-content-between align-items-center"
                onMouseDown={folderDivhandleMouseDown}
                onMouseMove={folderDivhandleMouseMove}
                onMouseUp={folderDivhandleMouseUp}
                style={{ height: "20px", backgroundColor: "#fff" }}
            >
                <div className="h-100 d-flex align-items-center">
                    <span className=" ps-1 pe-1 h-100 d-flex align-items-center">
                        <img src={folderIcon} height={"18px"} alt="Icon" />
                    </span>
                    <span className=" pe-1 h-100 d-flex align-items-center" style={{ fontSize: "0.8rem" }}>
                        {selectedFolder?.folderName}
                    </span>
                </div>
                <div className="h-100 d-flex align-items-center ">
                    <span className="file-icon-dash pe-1 ps-1 h-100 d-flex align-items-center">
                        <i className="bi bi-dash"></i>
                    </span>
                    <span className="file-icon-x pe-1 ps-1 h-100 d-flex align-items-center" onClick={() => setIsFolderOpened(false)}>
                        <i style={{ fontSize: "0.8rem" }} className="bi bi-x-lg"></i>
                    </span>
                </div>
            </div>
            <div className="content-body text-light " style={{ height: "480px", fontSize: "0.8rem" }}>
                Contents To Be Added...
            </div>
        </div>
    );
};

export default FolderApp;
