import React, { useState, useEffect } from "react";
import mspaint from "../../../images/mspaint.png";
const MsPaint = ({ isMsPaintOpened, setIsMsPaintOpened }) => {
    const [msPaintPosition, setMsPaintPosition] = useState({ x: 200, y: 80 });
    const [isMsPaintDragging, setMsPaintIsDragging] = useState(false);
    const [initialMsPaintPosition, setMsPaintInitialPosition] = useState({ x: 0, y: 0 });

    const msPaintHandleMouseDown = (event) => {
        setMsPaintIsDragging(true);
        setMsPaintInitialPosition({
            x: event.clientX - msPaintPosition.x,
            y: event.clientY - msPaintPosition.y,
        });
    };

    const msPaintHandleMouseMove = (event) => {
        if (isMsPaintDragging) {
            setMsPaintPosition({
                x: event.clientX - initialMsPaintPosition.x,
                y: event.clientY - initialMsPaintPosition.y,
            });
        }
    };

    const msPaintHandleMouseUp = () => {
        setMsPaintIsDragging(false);
    };

    useEffect(() => {
        if (isMsPaintDragging) {
            document.addEventListener("mousemove", msPaintHandleMouseMove);
            document.addEventListener("mouseup", msPaintHandleMouseUp);
        } else {
            document.removeEventListener("mousemove", msPaintHandleMouseMove);
            document.removeEventListener("mouseup", msPaintHandleMouseUp);
        }

        return () => {
            document.removeEventListener("mousemove", msPaintHandleMouseMove);
            document.removeEventListener("mouseup", msPaintHandleMouseUp);
        };
    }, [isMsPaintDragging]);
    // Code for Draggable Div Ended
    return (
        <div
            className={`drag ${isMsPaintOpened ? "d-block" : "d-none"}`}
            style={{
                height: "550px",
                width: "750px",
                backgroundColor: "#222",
                left: msPaintPosition.x,
                top: msPaintPosition.y,
                userSelect: "none",
            }}
        >
            <div
                className="head d-flex justify-content-between align-items-center"
                onMouseDown={msPaintHandleMouseDown}
                onMouseMove={msPaintHandleMouseMove}
                onMouseUp={msPaintHandleMouseUp}
                style={{ height: "20px", backgroundColor: "#fff" }}
            >
                <div className="h-100 d-flex align-items-center">
                    <span className=" ps-1 pe-1 h-100 d-flex align-items-center">
                        <img src={mspaint} height={"18px"} alt="Icon" />
                    </span>
                    <span className=" pe-1 h-100 d-flex align-items-center" style={{ fontSize: "0.8rem" }}>
                        MS Paint
                    </span>
                </div>
                <div className="h-100 d-flex align-items-center ">
                    <span className="file-icon-dash pe-1 ps-1 h-100 d-flex align-items-center">
                        <i className="bi bi-dash"></i>
                    </span>
                    <span className="file-icon-x pe-1 ps-1 h-100 d-flex align-items-center" onClick={() => setIsMsPaintOpened(false)}>
                        <i style={{ fontSize: "0.8rem" }} className="bi bi-x-lg"></i>
                    </span>
                </div>
            </div>
            <div style={{ height: "530px" }}>
                <iframe width="100%" height="100%" src="https://paint.js.org/" title="Ms Paint"></iframe>
            </div>
        </div>
    );
};

export default MsPaint;
