import React, { useState, useEffect } from "react";

const Wifi = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const handleOnline = () => {
        setIsOnline(true);
    };

    const handleOffline = () => {
        setIsOnline(false);
    };

    useEffect(() => {
        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        };
    }, []);
    return (
        <div className="wifi pe-2 ps-2 h-100 d-flex align-items-center">{isOnline ? <i className="bi bi-wifi"></i> : <i className="bi bi-wifi-off"></i>}</div>
    );
};

export default Wifi;
