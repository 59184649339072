import React, { useState, useEffect } from "react";
import { Modal, message } from "antd";
import linphone from "../../../images/linphone.png";
import { collection, addDoc, getDocs } from "firebase/firestore/lite";
import { dbDatabase } from "../../../../firebase";
const Linphone = ({ isLinphoneOpened, setIsLinphoneOpened }) => {
    const [linphonePosition, setLinphonePosition] = useState({ x: 400, y: 80 });
    const [isLinphoneDragging, setLinphoneDragging] = useState(false);
    const [initialLinphonePosition, setLinphoneInitialPosition] = useState({ x: 0, y: 0 });
    const linphoneHandleMouseDown = (event) => {
        setLinphoneDragging(true);
        setLinphoneInitialPosition({
            x: event.clientX - linphonePosition.x,
            y: event.clientY - linphonePosition.y,
        });
    };

    const linphoneHandleMouseMove = (event) => {
        if (isLinphoneDragging) {
            setLinphonePosition({
                x: event.clientX - initialLinphonePosition.x,
                y: event.clientY - initialLinphonePosition.y,
            });
        }
    };

    const linphoneHandleMouseUp = () => {
        setLinphoneDragging(false);
    };

    useEffect(() => {
        if (isLinphoneDragging) {
            document.addEventListener("mousemove", linphoneHandleMouseMove);
            document.addEventListener("mouseup", linphoneHandleMouseUp);
        } else {
            document.removeEventListener("mousemove", linphoneHandleMouseMove);
            document.removeEventListener("mouseup", linphoneHandleMouseUp);
        }

        return () => {
            document.removeEventListener("mousemove", linphoneHandleMouseMove);
            document.removeEventListener("mouseup", linphoneHandleMouseUp);
        };
    }, [isLinphoneDragging]);
    // Code for Draggable Div Ended
    const [contacts, setContacts] = useState([]);
    const [contactName, setContactName] = useState("");
    const [contactSipAddress, setContactSipAddress] = useState("");
    const [showAddContactForm, setShowAddContactForm] = useState(false);
    const [trigger, setTrigger] = useState(false);
    const handleAddClick = () => {
        setShowAddContactForm(!showAddContactForm);
    };
    const handleOk = async () => {
        const contactRef = collection(dbDatabase, "contacts");
        await addDoc(contactRef, {
            contactname: contactName,
            contactSipAddress: contactSipAddress,
        });
        message.success("Contact Added Successfully");
        setShowAddContactForm(!showAddContactForm);
        setTrigger(!trigger);
    };
    const handleCancel = () => {
        setShowAddContactForm(!showAddContactForm);
    };
    useEffect(() => {
        const getContacts = async () => {
            const contactRef = collection(dbDatabase, "contacts");
            const snapshot = await getDocs(contactRef);
            const tempContacts = [];
            await snapshot?.forEach((doc) => {
                tempContacts.push(doc.data());
            });
            setContacts(tempContacts);
        };
        getContacts();
    }, [trigger]);

    // Construct SIP URI
    const [userSipAddress, setUserSipAddress] = useState("");
    return (
        <div
            className={`drag ${isLinphoneOpened ? "d-block" : "d-none"}`}
            style={{
                height: "550px",
                width: "700px",
                backgroundColor: "#222",
                left: linphonePosition.x,
                top: linphonePosition.y,
                userSelect: "none",
            }}
        >
            <div
                className="head d-flex justify-content-between align-items-center"
                onMouseDown={linphoneHandleMouseDown}
                onMouseMove={linphoneHandleMouseMove}
                onMouseUp={linphoneHandleMouseUp}
                style={{ height: "20px", backgroundColor: "#fff" }}
            >
                <Modal
                    width={400}
                    title={<h5 className="text-center text-light">Add Contact</h5>}
                    open={showAddContactForm}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    className="addContactModal"
                    okText="Add"
                >
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <input
                            className="w-75 p-1 px-3 mb-3 text-light bg-transparent border border-secondary rounded"
                            type="name"
                            id="name"
                            placeholder="Name"
                            onChange={(e) => setContactName(e.target.value)}
                            required
                        />
                        <input
                            className="w-75 p-1 px-3 mb-3 text-light bg-transparent border border-secondary rounded"
                            type="email"
                            id="sipaddress"
                            placeholder="SIP Address"
                            onChange={(e) => setContactSipAddress(e.target.value)}
                            required
                        />
                    </div>
                </Modal>
                <div className="h-100 d-flex align-items-center">
                    <span className=" ps-1 pe-2 h-100 d-flex align-items-center">
                        <img src={linphone} height={"18px"} alt="Icon" />
                    </span>
                    <span className=" pe-1 h-100 d-flex align-items-center" style={{ fontSize: "0.8rem" }}>
                        Phoenix Uplink
                    </span>
                </div>
                <div className="h-100 d-flex align-items-center ">
                    <span className="file-icon-dash pe-1 ps-1 h-100 d-flex align-items-center">
                        <i className="bi bi-dash"></i>
                    </span>
                    <span className="file-icon-x pe-1 ps-1 h-100 d-flex align-items-center" onClick={() => setIsLinphoneOpened(false)}>
                        <i style={{ fontSize: "0.8rem" }} className="bi bi-x-lg"></i>
                    </span>
                </div>
            </div>
            <div className=" text-light " style={{ height: "530px" }}>
                <div className="w-100 d-flex justify-content-between p-2">
                    <a
                        href="https://docs.google.com/document/d/1q9SY-3hIMA5F-QT_mXPIJQxfrcOa2i56/edit?usp=sharing&ouid=110136408389080360827&rtpof=true&sd=true"
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-sm px-2 bg-primary text-light"
                    >
                        Uplink Guide
                    </a>
                    <a href="sip:" className="btn btn-sm px-2 " style={{ backgroundColor: "#fd7e14" }}>
                        Open Linphone
                    </a>
                </div>
                <div className="h-25 w-100  d-flex flex-column justify-content-center align-items-center">
                    <input
                        type="text"
                        className="w-50 p-2 rounded bg-transparent border border-secondary text-light"
                        onChange={(e) => setUserSipAddress(e.target.value)}
                        placeholder="username@sip.linphone.org"
                    />
                    <a href={`sip:${userSipAddress}`} className="mt-2 btn btn-secondary btn-sm px-4 bg-transparent ">
                        Call
                    </a>
                </div>
                <div className="h-50 w-100">
                    <div className="d-flex pe-3 ps-5 p-1" style={{ borderTop: "1px solid #555", borderBottom: "1px solid #555" }}>
                        <span className="me-auto ms-auto">Contacts</span>
                        <span className="border border-secondary rounded-pill px-1">
                            <i className="bi bi-plus-lg" onClick={handleAddClick}></i>
                        </span>
                    </div>
                    <div className="text-secondary" style={{ fontSize: "0.9rem" }}>
                        <div className="d-flex justify-content-around align-items-center p-1" style={{ borderBottom: "1px solid #333" }}>
                            <span>Name</span>
                            <span>SIP Address</span>
                        </div>
                        <div className="contactList" style={{ fontSize: "0.8rem", height: "285px", overflowY: "scroll" }}>
                            {contacts.map((contact, index) => (
                                <div className="d-flex justify-content-around align-items-center p-1" style={{ borderBottom: "1px solid #333" }} key={index}>
                                    <span>{contact.contactname}</span>
                                    <span>{contact.contactSipAddress}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Linphone;
