import "./Broker.css";
const Broker = () => {
    return (
        <div className="broker-page">
            <nav className="navbar navbar-expand-md bg-dark navbar-dark ">
                <div className="container-fluid p-4">
                    <a className="navbar-brand" href="/">
                        Phoenix Labs
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end " id="collapsibleNavbar">
                        <ul className="navbar-nav ml-auto gap-4">
                            <li className="nav-item ">
                                <a className="nav-link text-white" href="/portal/datacenter/virtualdesktop">
                                    Manages computing resources
                                </a>
                            </li>
                            <li className="nav-item ">
                                <a className="nav-link text-white" href="/portal/datacenter/virtualdesktop">
                                    Optimizes resource utilization
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="broker-content">
                <div className="container">
                    <div className="row justify-content-center align-items-center h-100">
                        <div className="col-md-8 col-lg-12">
                            <div className="card shadow">
                                <div className="card-body">
                                    <h2 className="card-title text-center mb-4">Broker</h2>
                                    <p>
                                        To optimize your AI experience, our Broker efficiently manages computing resources within the Data Center. It
                                        intelligently allocates resources based on demand, ensuring that your AI jobs are completed in the shortest possible
                                        time. The Broker optimizes resource utilization, leading to cost-effective AI exploration without compromising on
                                        performance. Say goodbye to resource bottlenecks and experience the true power of our AI infrastructure.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Broker;
