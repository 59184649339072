import React from "react";

const Chevron = ({ isHiddenIconsVisible, setIsHiddenIconsVisible }) => {
    return (
        <div
            className={`toggle pe-2 ps-2 h-100 d-flex align-items-center ${isHiddenIconsVisible ? "opened" : ""}`}
            onClick={() => setIsHiddenIconsVisible(!isHiddenIconsVisible)}
        >
            <i className="bi bi-chevron-up"></i>
        </div>
    );
};

export default Chevron;
