import React from "react";
import folderIcon from "../../../images/foldericon.png";
const FolderIcon = ({ handleContextMenuOpen, handleOpenFolder, folder, selectedFolder }) => {
    return (
        <div className="folder col-1 text-center " style={{ cursor: "pointer" }}>
            <img
                src={folderIcon}
                height="40px"
                alt="Folder Icon"
                onClick={(e) => {
                    handleContextMenuOpen(e, folder);
                    handleOpenFolder(selectedFolder);
                }}
            />
            <i className="bi bi-three-dots-vertical position-absolute text-light ms-2" onClick={(e) => handleContextMenuOpen(e, folder)}></i>
            <p className="w-100 text-light" style={{ fontSize: "0.8rem" }}>
                {folder.folderName}
            </p>
        </div>
    );
};

export default FolderIcon;
