import React from "react";

const SoftwareIcon = ({ icon, openSoftware, setOpenSoftware, upperClass }) => {
    return (
        <div className={` ${upperClass} h-100 d-flex align-items-center ps-2 pe-2  ${openSoftware ? "opened" : ""} `}>
            <div onClick={() => setOpenSoftware(true)}>
                <img src={icon} alt="VsCode" height={"20px"} />
            </div>
        </div>
    );
};

export default SoftwareIcon;
