import React, { useState } from "react";

const NewFolderCard = ({ setFolders, setIsInputBarVisibleForNewFolder }) => {
    const [newFolderName, setNewFolderName] = useState("");
    const handleCreateSubmit = () => {
        if (newFolderName.trim() !== "") {
            const newFolder = {
                id: String(Date.now()),
                folderName: newFolderName,
                data: {},
            };

            setFolders((prevFolders) => [...prevFolders, newFolder]);
            setNewFolderName("");
            setIsInputBarVisibleForNewFolder(false);
        }
    };
    return (
        <div
            className="position-absolute text-light text-center p-2"
            style={{ backgroundColor: "#222", fontSize: "0.8rem", width: "200px", top: "200px", left: "400px", zIndex: "100" }}
        >
            <div className="mb-2">
                Enter Folder Name
                <i className="bi bi-x-lg ms-3" style={{ cursor: "pointer" }} onClick={() => setIsInputBarVisibleForNewFolder(false)}></i>
            </div>
            <input
                type="text"
                className="rename w-100 bg-transparent rounded p-1 text-light"
                style={{ border: "1px solid #555" }}
                placeholder={"Name"}
                onChange={(e) => setNewFolderName(e.target.value)}
            />
            <button className="btn btn-secondary ps-1 pe-1 p-0 mt-2" style={{ fontSize: "0.8rem" }} onClick={handleCreateSubmit}>
                Create
            </button>
        </div>
    );
};

export default NewFolderCard;
