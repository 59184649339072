import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import { auth } from "../../firebase";
import { createUserWithEmailAndPassword, deleteUser } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore/lite";
import { dbDatabase } from "../../firebase";
import LoadingSpinner from "../Helpers/LoadingSpinner";
import faceIO from "@faceio/fiojs";
import HandleError from "./handleFaceioError";
const Register = ({ headQuarters }) => {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [location, setLocation] = useState("Juba");
    const [password, setPassword] = useState("");
    const [viewPass, setViewPass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [displayFacialButton, setDisplayFacialButton] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        await createUserWithEmailAndPassword(auth, email, password)
            .then(async () => {
                message.success("Registration Successfull! Add Now Add Your Facial ID");
                setIsLoading(false);
                setDisplayFacialButton(true);
            })
            .catch((error) => {
                const errorMessage = error.message;
                message.error(errorMessage);
                setIsLoading(false);
            });
        setIsLoading(false);
    };
    const faceio = new faceIO(process.env.REACT_APP_FACEIO_KEY);
    function enrollNewUser(e) {
        e.preventDefault();
        faceio
            .enroll({
                locale: "auto",
                userConsent: false,
                payload: {
                    whoami: `{${name}}`,
                    email: `${email}`,
                },
            })
            .then(async (userInfo) => {
                await setDoc(doc(dbDatabase, "users", email), {
                    name: name,
                    email: email,
                    location: location,
                    userInfo: userInfo,
                });
                message.success("User Registered Successfully");
                faceio.restartSession();
                navigate("/login");
            })
            .catch(async (errCode) => {
                const warning = HandleError(errCode);
                message.error(warning);
                faceio.restartSession();
                const delete_user = auth.currentUser;
                await deleteUser(delete_user);
            });
    }
    return (
        <div className="container-fluid login d-flex align-items-center justify-content-center container-fluid d-flex align-items-center justify-content-center register">
            <div className="col-10 col-sm-10 col-md-6 h-75 d-flex text-white login-shadow rounded">
                <div className="login-side h-100 bg-dark rounded-start p-2">
                    <h2 className="text-center login-head">REGISTER</h2>
                    <form className="p-2 h-75" onSubmit={handleSubmit}>
                        <div className="mt-2">
                            <div className="input-text mb-2">
                                <i className="bi bi-person-fill"></i>
                                <input
                                    className="w-100 bg-transparent p-2 text-white"
                                    type="text"
                                    name="name"
                                    id="name"
                                    autoComplete="name"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="input-email mb-2">
                                <i className="bi bi-envelope"></i>
                                <input
                                    className="w-100 bg-transparent p-2 text-white"
                                    type="email"
                                    name="email"
                                    id="email"
                                    autoComplete="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="input-location mb-2">
                                <i className="bi bi-geo-alt-fill"></i>
                                <select
                                    name="location"
                                    id="location"
                                    className="w-100 bg-transparent text-white p-2 "
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                    required
                                >
                                    <optgroup className="bg-dark">
                                        {headQuarters?.map((headQuarter, i) => {
                                            return (
                                                <option className="" value={headQuarter.data.city} key={i}>
                                                    {headQuarter.data.city}
                                                </option>
                                            );
                                        })}
                                    </optgroup>
                                </select>
                            </div>
                            <div className="input-password mb-2">
                                {viewPass ? (
                                    <i className="bi bi-eye-slash-fill" onClick={() => setViewPass(!viewPass)}></i>
                                ) : (
                                    <i className="bi bi-eye-fill" onClick={() => setViewPass(!viewPass)}></i>
                                )}

                                <input
                                    className="w-100 bg-transparent p-2 text-white"
                                    type={`${viewPass ? "text" : "password"}`}
                                    name="password"
                                    id="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    autoComplete="current-password"
                                />
                            </div>
                        </div>
                        <div className="signup p-2 mb-2">
                            <p className="m-0 ">
                                Already have an account?
                                <Link className="ps-1" to="/login">
                                    Login!
                                </Link>
                            </p>
                        </div>
                        <div className="w-75 m-auto d-flex justify-content-between">
                            <Link className="text-center button " to="/">
                                Cancel
                            </Link>
                            {isLoading ? (
                                <LoadingSpinner />
                            ) : (
                                <button type="submit" className="text-center button">
                                    Register
                                </button>
                            )}
                        </div>
                        {displayFacialButton && (
                            <div className="w-100 h-25 mt-5">
                                <button onClick={enrollNewUser} className="text-center button w-100 h-100">
                                    Click here to register your facial ID.
                                </button>
                            </div>
                        )}
                    </form>
                </div>
                <div className="text-side h-100 text-center d-none d-sm-flex align-items-center img-bg rounded-end p-5 ps-0 ">
                    <div className="w-100">
                        <h1 className="text-end">WELCOME TO REGISTER PAGE!</h1>
                        <p className="m-0 ps-4 mt-4" style={{ textAlign: "justify" }}>
                            Fill out the necessary information and press the register button. Then a button for facial recognition will appear.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
