import "./WebAccess.css";
const WebAccess = () => {
    return (
        <div className="web-access">
            <nav className="navbar navbar-expand-md bg-dark navbar-dark ">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        Phoenix Labs
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item ">
                                <a className="nav-link text-white" href="/portal/datacenter/webaccess">
                                    Secure authentication protocols and encryption
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="web-access-content">
                <div className="container">
                    <div className="row justify-content-center mt-4">
                        <div className="col-md-8 col-lg-12">
                            <div className="card text-light shadow" style={{ backgroundColor: "rgba(0,0,100,0.3)" }}>
                                <div className="card-body">
                                    <h2 className="card-title text-center mb-4">Web Access</h2>
                                    <p>
                                        Our Web Access feature offers the flexibility to connect to the Phoenix Labs AI ecosystem from anywhere in the world.
                                        With secure authentication protocols and encryption, accessing the Ionosphere Virtual Desktop and other resources
                                        through a web browser becomes convenient and secure. Work on your AI projects without being tied to a physical location,
                                        ensuring you can collaborate and innovate with ease.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WebAccess;
