import React, { useState, useEffect } from "react";
import vscode from "../../../images/vscode.png";
const VsCode = ({ isVsCodeOpened, setIsVsCodeOpened }) => {
    const [vsCodePosition, setVsCodePosition] = useState({ x: 200, y: 80 });
    const [isVsCodeDragging, setVsCodeIsDragging] = useState(false);
    const [initialVsCodePosition, setVsCodeInitialPosition] = useState({ x: 0, y: 0 });

    const vsCodeHandleMouseDown = (event) => {
        setVsCodeIsDragging(true);
        setVsCodeInitialPosition({
            x: event.clientX - vsCodePosition.x,
            y: event.clientY - vsCodePosition.y,
        });
    };

    const vsCodeHandleMouseMove = (event) => {
        if (isVsCodeDragging) {
            setVsCodePosition({
                x: event.clientX - initialVsCodePosition.x,
                y: event.clientY - initialVsCodePosition.y,
            });
        }
    };

    const vsCodeHandleMouseUp = () => {
        setVsCodeIsDragging(false);
    };

    useEffect(() => {
        if (isVsCodeDragging) {
            document.addEventListener("mousemove", vsCodeHandleMouseMove);
            document.addEventListener("mouseup", vsCodeHandleMouseUp);
        } else {
            document.removeEventListener("mousemove", vsCodeHandleMouseMove);
            document.removeEventListener("mouseup", vsCodeHandleMouseUp);
        }

        return () => {
            document.removeEventListener("mousemove", vsCodeHandleMouseMove);
            document.removeEventListener("mouseup", vsCodeHandleMouseUp);
        };
    }, [isVsCodeDragging]);
    // Code for Draggable Div Ended

    return (
        <div
            className={`drag ${isVsCodeOpened ? "d-block" : "d-none"}`}
            style={{
                height: "550px",
                width: "750px",
                backgroundColor: "#222",
                left: vsCodePosition.x,
                top: vsCodePosition.y,
                userSelect: "none",
            }}
        >
            <div
                className="head d-flex justify-content-between align-items-center"
                onMouseDown={vsCodeHandleMouseDown}
                onMouseMove={vsCodeHandleMouseMove}
                onMouseUp={vsCodeHandleMouseUp}
                style={{ height: "20px", backgroundColor: "#fff" }}
            >
                <div className="h-100 d-flex align-items-center">
                    <span className=" ps-1 pe-1 h-100 d-flex align-items-center">
                        <img src={vscode} height={"18px"} alt="Icon" />
                    </span>
                    <span className=" pe-1 h-100 d-flex align-items-center" style={{ fontSize: "0.8rem" }}>
                        Visual Studio Code
                    </span>
                </div>
                <div className="h-100 d-flex align-items-center ">
                    <span className="file-icon-dash pe-1 ps-1 h-100 d-flex align-items-center">
                        <i className="bi bi-dash"></i>
                    </span>
                    <span className="file-icon-x pe-1 ps-1 h-100 d-flex align-items-center" onClick={() => setIsVsCodeOpened(false)}>
                        <i style={{ fontSize: "0.8rem" }} className="bi bi-x-lg"></i>
                    </span>
                </div>
            </div>
            <div style={{ height: "530px" }}>
                <iframe width="100%" height="100%" src="https://github1s.com/" title="Vs Code"></iframe>
            </div>
        </div>
    );
};

export default VsCode;
