import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore/lite";
import { dbDatabase } from "../../../firebase";
import Header from "./components/header/Header";
import Sidebar from "./components/sidebar/index";
import FilesView from "./components/filesView/FilesView";
import SideIcons from "./components/sideIcons/SideIcons";
import { Navigate } from "react-router-dom";
import "./Storage.css";
const Storage = () => {
    const [user, setUser] = useState({});
    const [state, setState] = useState(false);
    useEffect(() => {
        const fetch = async () => {
            try {
                const email = await localStorage.getItem("user_phoenixlab");
                const userDoc = doc(dbDatabase, "users", email);
                const userSnap = await getDoc(userDoc);
                setUser(userSnap.data());
                setState(!state);
            } catch (error) {
                console.log(error);
            }
        };
        fetch();
    }, []);
    return (
        <div className="Storage">
            {user ? (
                <>
                    <Header user={user} setUser={setUser} />
                    <div style={{ display: "flex" }}>
                        <Sidebar state={state} setState={setState} userEmail={user.email} />
                        <FilesView state={state} setState={setState} userEmail={user.email} />
                        <SideIcons />
                    </div>
                </>
            ) : (
                Navigate("/login")
            )}
        </div>
    );
};

export default Storage;
