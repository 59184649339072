import React, { useState, useEffect } from "react";

const FullScreen = () => {
    // Code For Adding Full Screenmode Functionality
    const [isFullScreen, setIsFullScreen] = useState(false);
    // Toggle full screen mode
    const toggleFullScreen = () => {
        if (!isFullScreen) {
            enterFullScreen();
        } else {
            exitFullScreen();
        }
    };

    // Request entering full screen
    const enterFullScreen = () => {
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.msRequestFullscreen) {
            document.documentElement.msRequestFullscreen();
        }
        setIsFullScreen(true);
    };

    // Exit full screen
    const exitFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
        setIsFullScreen(false);
    };

    // Listen for full screen change events and update state accordingly
    useEffect(() => {
        document.addEventListener("fullscreenchange", handleFullScreenChange);
        document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
        document.addEventListener("mozfullscreenchange", handleFullScreenChange);
        document.addEventListener("MSFullscreenChange", handleFullScreenChange);

        return () => {
            document.removeEventListener("fullscreenchange", handleFullScreenChange);
            document.removeEventListener("webkitfullscreenchange", handleFullScreenChange);
            document.removeEventListener("mozfullscreenchange", handleFullScreenChange);
            document.removeEventListener("MSFullscreenChange", handleFullScreenChange);
        };
    }, []);

    // Handle changes in full screen mode and update state
    const handleFullScreenChange = () => {
        setIsFullScreen(
            !!document.fullscreenElement || !!document.webkitFullscreenElement || !!document.mozFullScreenElement || !!document.msFullscreenElement
        );
    };
    // Fullscreen Mode Code Ended
    return (
        <div>
            <button onClick={toggleFullScreen} className="fullscreen-btn position-absolute end-0 p-2 bg-transparent border-0 ">
                {isFullScreen ? <i className="bi bi-fullscreen-exit text-white"></i> : <i className="bi bi-fullscreen text-white"></i>}
            </button>
        </div>
    );
};

export default FullScreen;
