import { useEffect, useState } from "react";
import "./Map.css";
import mapboxgl from "mapbox-gl";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
const Map = ({ headQuarters, loading }) => {
    const [location, setLocation] = useState({ city: "Default", country: "Default", longitude: "72.8777", latitude: "19.076" });

    useEffect(() => {
        mapboxgl.accessToken = "pk.eyJ1IjoiaGVsbG84MDA0IiwiYSI6ImNsaWZoaGU3bzB0cjIza3MwZ3U3bjZhbjgifQ.WvTmxC8woHj0QXfGURZ6wQ";
        const secondsPerRevolution = 100;
        const maxSpinZoom = 5;
        const slowSpinZoom = 3;
        let userInteracting = false;
        let spinEnabled = true;
        var map = new mapboxgl.Map({
            container: "map",
            style: "mapbox://styles/mapbox/satellite-streets-v10",
            projection: "globe",
            center: [72.8777, 19.076],
            zoom: 0,
            attributionControl: false,
        });
        const target = {
            center: [location.longitude, location.latitude],
            zoom: 10.5,
            bearing: 130,
            pitch: 75,
        };
        const fly = () => {
            map.flyTo({
                ...target,
                duration: 5000,
                essential: true,
            });
        };
        fly();
        function spinGlobe() {
            const zoom = map.getZoom();
            if (spinEnabled && !userInteracting && zoom < maxSpinZoom) {
                let distancePerSecond = 360 / secondsPerRevolution;
                if (zoom > slowSpinZoom) {
                    const zoomDif = (maxSpinZoom - zoom) / (maxSpinZoom - slowSpinZoom);
                    distancePerSecond *= zoomDif;
                }
                const center = map.getCenter();
                center.lng -= distancePerSecond;
                map.easeTo({ center, duration: 1000, easing: (n) => n });
            }
        }
        map.on("mousedown", () => {
            userInteracting = true;
        });
        map.on("mouseup", () => {
            userInteracting = false;
            spinGlobe();
        });
        map.on("dragend", () => {
            userInteracting = false;
            spinGlobe();
        });
        map.on("pitchend", () => {
            userInteracting = false;
            spinGlobe();
        });
        map.on("rotateend", () => {
            userInteracting = false;
            spinGlobe();
        });
        map.on("moveend", () => {
            spinGlobe();
        });

        headQuarters.length > 0
            ? headQuarters.map((headQuarter) => {
                  new mapboxgl.Marker().setLngLat([headQuarter.data.longitude, headQuarter.data.latitude]).addTo(map);
              })
            : new mapboxgl.Marker().setLngLat([72.8777, 19.076]).addTo(map);
    }, [location]);

    return (
        <div className="Map container-fluid p-0">
            <div className="row w-100 p-0 m-0 ">
                <div className="col-12 col-sm-5 col-md-3 border-bottom">
                    <h4 className="text-center text-secondary mt-2">Phoenix Labs Global Data Centers</h4>
                    <div className="scroll " style={{ height: "84vh", overflowY: "scroll" }}>
                        {loading ? (
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <CircularProgress color="inherit" />
                            </div>
                        ) : (
                            <>
                                {headQuarters?.map((headQuarter, i) => {
                                    return (
                                        <div className="border p-3 rounded mb-3 shadow" key={i}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span>{headQuarter.data.city}</span>
                                                <span>{headQuarter.data.country}</span>
                                            </div>
                                            <hr className="m-2 ms-0 me-0" />
                                            <div className="w-100 overflow-hidden ps-2 pe-2">
                                                <img
                                                    className="w-100 rounded"
                                                    style={{ height: "120px" }}
                                                    src={headQuarter.data.flagImage}
                                                    alt="locationImage"
                                                />
                                            </div>
                                            <hr className="m-2 ms-0 me-0" />
                                            <div className="d-flex justify-content-between align-items-center">
                                                <Link to="/login" className="btn btn-sm rounded border border-1 border-secondary ps-2 pe-2">
                                                    Login
                                                </Link>
                                                <button
                                                    className="btn btn-sm rounded border border-1 border-secondary ps-2 pe-2"
                                                    onClick={() => setLocation(headQuarters[i].data)}
                                                >
                                                    Locate<i className="bi bi-arrow-right-short"></i>
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </div>
                    <div className="text-center mt-2 mb-2">
                        <Link className="btn btn-sm rounded border border-1 border-secondary ps-2 pe-2" to="/">
                            Home
                        </Link>
                    </div>
                </div>
                <div className="col-12 col-sm-7 col-md-9 map-container bg-white p-2">
                    <div className="rounded" id="map"></div>
                </div>
            </div>
        </div>
    );
};

export default Map;
