import React, { useEffect, useRef } from "react";

const RightClickMenu = ({ setContextMenuVisible, contextMenuPosition, setIsInputBarVisibleForNewFolder }) => {
    const contextMenuRef = useRef(null);
    const handleCloseContextMenu = () => {
        setContextMenuVisible(false);
    };
    const handleClickOutside = (event) => {
        if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
            handleCloseContextMenu();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const handleReload = () => {
        window.location.reload();
    };
    return (
        <div className="context-menu" style={{ fontSize: "0.8rem", top: contextMenuPosition.y, left: contextMenuPosition.x }} ref={contextMenuRef}>
            <div className="option d-flex justify-content-between pe-2 ps-2 p-1" onClick={() => setIsInputBarVisibleForNewFolder(true)}>
                <div className="optionName">New</div>
                <div className="optionIcon">
                    <i className="bi bi-chevron-right"></i>
                </div>
            </div>
            <div onClick={handleReload} className=" d-flex justify-content-between pe-2 ps-2 p-1" style={{ cursor: "pointer" }}>
                <div className="optionName">Refresh</div>
                <div className="optionIcon">
                    <i className="bi bi-arrow-clockwise"></i>
                </div>
            </div>
        </div>
    );
};

export default RightClickMenu;
