import React, { useEffect, useState } from "react";
import filemanager from "../../../images/filemanager.png";
import LaptopChromebookIcon from "@mui/icons-material/LaptopChromebook";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import PhotoIcon from "@mui/icons-material/Photo";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import MovieIcon from "@mui/icons-material/Movie";
import AddIcon from "@mui/icons-material/Add";
import { dbDatabase, dbStorage } from "../../../../firebase";
import { collection, getDocs, addDoc, serverTimestamp } from "firebase/firestore/lite";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import File from "./File.js";
import { message } from "antd";
const FileManager = ({ isFileManagerOpened, setIsFileManagerOpened }) => {
    const [stateChange, setStateChange] = useState(false);

    const [fileManagerPosition, setFileManagerPosition] = useState({ x: 400, y: 100 });
    const [isFileManagerDragging, setFileManagerIsDragging] = useState(false);
    const [initialFileManagerPosition, setFilemanagerInitialPosition] = useState({ x: 0, y: 0 });

    const fileManagerhandleMouseDown = (event) => {
        setFileManagerIsDragging(true);
        setFilemanagerInitialPosition({
            x: event.clientX - fileManagerPosition.x,
            y: event.clientY - fileManagerPosition.y,
        });
    };

    const fileManagerhandleMouseMove = (event) => {
        if (isFileManagerDragging) {
            setFileManagerPosition({
                x: event.clientX - initialFileManagerPosition.x,
                y: event.clientY - initialFileManagerPosition.y,
            });
        }
    };

    const fileManagerhandleMouseUp = () => {
        setFileManagerIsDragging(false);
    };

    useEffect(() => {
        if (isFileManagerDragging) {
            document.addEventListener("mousemove", fileManagerhandleMouseMove);
            document.addEventListener("mouseup", fileManagerhandleMouseUp);
        } else {
            document.removeEventListener("mousemove", fileManagerhandleMouseMove);
            document.removeEventListener("mouseup", fileManagerhandleMouseUp);
        }

        return () => {
            document.removeEventListener("mousemove", fileManagerhandleMouseMove);
            document.removeEventListener("mouseup", fileManagerhandleMouseUp);
        };
    }, [isFileManagerDragging]);

    const [folderNames, setFolderNames] = useState([
        { id: 1, folderName: "Desktop", forderIcon: <LaptopChromebookIcon fontSize="small" style={{ color: "blue" }} /> },
        { id: 2, folderName: "Documents", forderIcon: <DocumentScannerIcon fontSize="small" style={{ color: "blue" }} /> },
        { id: 3, folderName: "Pictures", forderIcon: <PhotoIcon fontSize="small" style={{ color: "blue" }} /> },
        { id: 4, folderName: "Music", forderIcon: <MusicNoteIcon fontSize="small" style={{ color: "blue" }} /> },
        { id: 5, folderName: "Video", forderIcon: <MovieIcon fontSize="small" style={{ color: "blue" }} /> },
    ]);
    // Function to check if a file is a document (e.g., PDF, DOCX)
    function isDocument(fileName) {
        const documentExtensions = [".pdf", ".docx", ".txt"]; // Add more extensions if needed
        const fileExtension = getFileExtension(fileName);
        return documentExtensions.includes(fileExtension.toLowerCase());
    }

    // Function to check if a file is a picture (e.g., JPG, PNG)
    function isPicture(fileName) {
        const pictureExtensions = [".jpg", ".jpeg", ".png", ".gif"]; // Add more extensions if needed
        const fileExtension = getFileExtension(fileName);
        return pictureExtensions.includes(fileExtension.toLowerCase());
    }

    // Function to check if a file is music (e.g., MP3, WAV)
    function isMusic(fileName) {
        const musicExtensions = [".mp3", ".wav"]; // Add more extensions if needed
        const fileExtension = getFileExtension(fileName);
        return musicExtensions.includes(fileExtension.toLowerCase());
    }

    // Function to check if a file is a video (e.g., MP4, AVI)
    function isVideo(fileName) {
        const videoExtensions = [".mp4", ".avi", ".mkv"]; // Add more extensions if needed
        const fileExtension = getFileExtension(fileName);
        return videoExtensions.includes(fileExtension.toLowerCase());
    }

    // Helper function to get the file extension from a file name
    function getFileExtension(fileName) {
        const parts = fileName.split(".");
        if (parts.length > 1) {
            return "." + parts.pop();
        }
        return "";
    }
    // Code for filtering files according to there extensions
    const [documentFiles, setDocumentFiles] = useState([]);
    const [pictureFiles, setPictureFiles] = useState([]);
    const [musicFiles, setMusicFiles] = useState([]);
    const [videoFiles, setVideoFiles] = useState([]);
    const [allFiles, setAllFiles] = useState([]);
    const [displayedFiles, setDisplayedFiles] = useState([]);
    useEffect(() => {
        const fetch = async () => {
            const tempFiles = [];
            try {
                const userEmail = localStorage.getItem("user_phoenixlab");
                const collectionPath = `${userEmail}`;
                const querySnapshot = await getDocs(collection(dbDatabase, collectionPath));
                await querySnapshot.forEach((doc) => {
                    tempFiles.push({
                        id: doc.id,
                        item: doc.data(),
                    });
                });
                setDisplayedFiles(tempFiles);
                setAllFiles(tempFiles);
                // Categorize files based on their format
                const categorizedFiles = {
                    documents: [],
                    pictures: [],
                    music: [],
                    videos: [],
                };

                await tempFiles.forEach((file) => {
                    if (isDocument(file.item.caption)) {
                        categorizedFiles.documents.push(file);
                    } else if (isPicture(file.item.caption)) {
                        categorizedFiles.pictures.push(file);
                    } else if (isMusic(file.item.caption)) {
                        categorizedFiles.music.push(file);
                    } else if (isVideo(file.item.caption)) {
                        categorizedFiles.videos.push(file);
                    }
                });

                setDocumentFiles(categorizedFiles.documents);
                setPictureFiles(categorizedFiles.pictures);
                setMusicFiles(categorizedFiles.music);
                setVideoFiles(categorizedFiles.videos);
            } catch (error) {
                console.log(error);
            }
        };
        fetch();
    }, [stateChange]);

    function filterFilesByFolder(folder) {
        if (folder === "pictures") {
            return pictureFiles;
        } else if (folder === "documents") {
            return documentFiles;
        } else if (folder === "music") {
            return musicFiles;
        } else if (folder === "video") {
            return videoFiles;
        } else {
            return allFiles;
        }
    }

    function handleDisplayedFiles(folder) {
        const files = filterFilesByFolder(folder);
        setDisplayedFiles(files);
    }
    // Code for uploading new files
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [noOfFiles, setNoOfFiles] = useState(0);
    const [uploading, setUploading] = useState(false);
    const handleChange = async (e) => {
        const files = e.target.files;
        const updatedSelectedFiles = [];
        for (let i = 0; i < files.length; i++) {
            await updatedSelectedFiles.push(files[i]);
        }
        setNoOfFiles(files.length);
        await setSelectedFiles(updatedSelectedFiles);
        setShowAlert(true);
    };
    const userEmail = localStorage.getItem("user_phoenixlab");
    const handleUpload = async () => {
        setUploading(true);
        if (selectedFiles.length > 0) {
            for (let i = 0; i < selectedFiles.length; i++) {
                const storeFile = selectedFiles[i];
                const fileRef = ref(dbStorage, `files/${userEmail}/${storeFile.name}`);
                const snapshot = await uploadBytes(fileRef, storeFile);
                const downloadURL = await getDownloadURL(snapshot.ref);
                try {
                    const collectionName = `${userEmail}`;
                    const myFilesRef = collection(dbDatabase, collectionName);
                    await addDoc(myFilesRef, {
                        timestamp: serverTimestamp(),
                        caption: storeFile.name,
                        fileUrl: downloadURL,
                        size: snapshot.metadata.size,
                    });
                    message.success("File Uploaded!");
                    setSelectedFiles([]);
                    setShowAlert(false);
                    setStateChange(!stateChange);
                    setUploading(false);
                } catch (error) {
                    setSelectedFiles([]);
                    message.error("Unable to upload files!");
                    console.log("Error In Uploading File", error);
                    setStateChange(!stateChange);
                    setUploading(false);
                }
            }
        } else {
            message.warning("No files selected!");
            setUploading(false);
        }
    };
    const handleCancel = () => {
        setShowAlert(false);
        setSelectedFiles([]);
        setUploading(false);
        setNoOfFiles(0);
    };
    return (
        <div
            className={`drag fileManagerDiv ${isFileManagerOpened ? "d-block" : "d-none"}`}
            style={{ left: fileManagerPosition.x, top: fileManagerPosition.y, userSelect: "none" }}
        >
            <div
                className="head d-flex justify-content-between align-items-center"
                onMouseDown={fileManagerhandleMouseDown}
                onMouseMove={fileManagerhandleMouseMove}
                onMouseUp={fileManagerhandleMouseUp}
                style={{ height: "20px", backgroundColor: "#fff" }}
            >
                <div className="h-100 d-flex align-items-center">
                    <span className=" ps-1 pe-1 h-100 d-flex align-items-center">
                        <img src={filemanager} height={"18px"} alt="Icon" />
                    </span>
                    <span className=" pe-1 h-100 d-flex align-items-center" style={{ fontSize: "0.8rem" }}>
                        File Explorer
                    </span>
                </div>
                <div className="h-100 d-flex align-items-center ">
                    <span className="file-icon-dash pe-1 ps-1 h-100 d-flex align-items-center">
                        <i className="bi bi-dash"></i>
                    </span>
                    <span className="file-icon-x pe-1 ps-1 h-100 d-flex align-items-center" onClick={() => setIsFileManagerOpened(false)}>
                        <i style={{ fontSize: "0.8rem" }} className="bi bi-x-lg"></i>
                    </span>
                </div>
            </div>
            <div className="content-body text-light " style={{ height: "480px", fontSize: "0.8rem" }}>
                {showAlert && (
                    <div
                        className="bg-light position-absolute d-flex flex-column align-items-center justify-content-center"
                        style={{ height: "100px", width: "200px", top: "30px", right: "35%", borderRadius: "6px", boxShadow: "0 0 10px #000", zIndex: "100" }}
                    >
                        <p className="text-dark m-0 text-center">
                            Are you sure? <br /> Do you want to upload {noOfFiles} file?
                        </p>
                        {uploading ? (
                            <div className="text-dark">Uploading...</div>
                        ) : (
                            <div className="w-100 d-flex justify-content-around mt-3">
                                <button onClick={handleCancel}>No</button>
                                <button onClick={handleUpload}>Yes</button>
                            </div>
                        )}
                    </div>
                )}
                <div className="d-flex">
                    <div className="sidebar p-2" style={{ height: "460px", width: "120px", borderRight: "1px solid #555" }}>
                        {folderNames?.map((folder, i) => (
                            <div
                                key={i}
                                className="p-1"
                                style={{ borderBottom: "1px solid #555", cursor: "pointer" }}
                                onClick={() => handleDisplayedFiles(folder.folderName.toLowerCase())}
                            >
                                <span className="fs-6">{folder.forderIcon}</span>
                                <span className="ps-2">{folder.folderName}</span>
                            </div>
                        ))}
                        <div className="p-2">
                            <input className="d-none" type="file" id="newFile" name="newFile" multiple onChange={handleChange} />
                            <label
                                htmlFor="newFile"
                                className="p-1 d-flex align-items-center"
                                style={{ borderRadius: "8px", boxShadow: "0 0 4px #888, 0 0 4px #333" }}
                            >
                                <AddIcon fontSize="small" />
                                <span className="ps-1">New File</span>
                            </label>
                        </div>
                    </div>
                    <div className="foldersView" style={{ width: "530px" }}>
                        <div
                            className="fileHeader w-100 d-flex justify-content-between align-items-center"
                            style={{ height: "25px", borderBottom: "1px solid #555", color: "#eee" }}
                        >
                            <span className="ps-4 " style={{ width: "40%", borderRight: "1px solid #555" }}>
                                File Name
                            </span>
                            <span className="ps-4 d-flex justify-content-center" style={{ width: "30%", borderRight: "1px solid #555" }}>
                                Type
                            </span>
                            <span className="ps-4 d-flex justify-content-center" style={{ width: "30%" }}>
                                Size
                            </span>
                        </div>
                        <div className="fileList" style={{ height: "435px", overflowY: "scroll" }}>
                            {displayedFiles?.map((file, i) => (
                                <File file={file} key={i} getFileExtension={getFileExtension} />
                            ))}
                        </div>
                    </div>
                </div>
                <div
                    className="fileFooter w-100 d-flex justify-content-between align-items-center"
                    style={{ height: "20px", borderTop: "1px solid #555", color: "#eee" }}
                >
                    <span className="ps-4">{displayedFiles.length} items </span>
                </div>
            </div>
        </div>
    );
};

export default FileManager;
